import type { VenuesResponse, VenuesParams } from '~~/types'

export const useVenues = () => {
	const { $singaApi } = useNuxtApp()
	const { resolveVenueObject } = useResolve()

	/**
	 * Resolves the venue page with the given hash
	 */
	const loadVenueDetails = async (hash: string) => {
		return await resolveVenueObject(hash)
	}

	/**
	 * Load venues list
	 * @param {VenuesParams} params - All parameters that are sent to the API
	 * @returns {Promise<VenuesResponse>}
	 */
	const loadVenues = async (params: VenuesParams): Promise<VenuesResponse> => {
		try {
			const response = await $singaApi.Venues.list(params)
			return {
				results: response.results,
				next: response.next,
				count: response.count
			}
		} catch (error) {
			console.error('Failed to load venues:', error)
			return {
				results: [],
				next: null,
				count: 0
			}
		}
	}

	const loadVenueCities = async () => {
		const geoLocationStore = useGeoLocationStore()
		const { getCities, setCities } = geoLocationStore
		const storeCities = getCities()

		if (!storeCities || storeCities.length === 0) {
			try {
				const response = await $singaApi.Cities.get()
				setCities(response.results)
				return response.results
			} catch (error) {
				console.error('Failed to fetch cities:', error)
				return []
			}
		}
		return storeCities
	}

	return {
		loadVenueDetails,
		loadVenues,
		loadVenueCities
	}
}
